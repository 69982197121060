//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      newImg: require("../../assets/err/500.png"),
    };
  },
};
